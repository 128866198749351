<template>
    <div class="main-page">
        <second-navbar />
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-4 d-flex flex-column align-items-center justify-content-center vh-100">
                    <div class="bd-highlight d-flex justify-content-center mb-3">
                       <div class="img-qrcode" style="width: 70%">
                           <img src="@/assets/icon/qrcode.png" alt="" style="width: 100%; height:auto">
                       </div>
                    </div>
                    <div class="bd-highlight w-100 mb-2">
                        <div class="d-flex flex-row align-items-center note py-2 px-3">
                            <div class="bd-highlight pe-3">
                                <img src="@/assets/icon/info.png" alt="">
                            </div>
                            <div class="bd-highlight pt-1">
                                <p class="text-primary fm-nunito fst-normal fw-600 fs-14 lh-14 mb-0">Tunjukkan QR Code ini ke resepsionis</p>
                            </div>
                        </div>
                    </div>
                    <div class="bd-highlight pb-3 w-100">
                        <div class="box-summary p-3">
                            <div class="d-flex flex-row align-items-center justify-content-between">
                                <div class="bd-highlight">
                                    <div class="d-flex flex-row align-items-center">
                                        <div class="bd-highlight pe-3">
                                            <img src="@/assets/Icon.png" alt="">
                                        </div>
                                        <div class="bd-highlight py-1">
                                            <div class="text-gray-1 fm-poppins fst-normal fw-500 fs-14 lh-14 mb-0">Sandiaga Uno</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="bd-highlight">
                                    <p class="text-gray-3 fm-nunito fst-normal fw-600 fs-12 lh-12 mb-0">#090729</p>
                                </div>
                            </div>
                            <div class="divider">
                                <hr class="mt-3 mb-2" style="border: 1px solid #F2F2F2;">
                            </div>
                            <div class="d-flex flex-row align-items-center">
                                <div class="bd-highlight pe-3">
                                    <div class="d-flex flex-row align-items-center">
                                        <div class="bd-highlight px-1">
                                            <img src="@/assets/icon/date.png" alt="">
                                        </div>
                                        <div class="bd-highlight px-1 pt-1">
                                            <div class="text-gray-3 fm-nunito fst-normal fw-600 fs-12 lh-12 mb-0">05/02/2021</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="bd-highlight">
                                    <div class="d-flex flex-row align-items-center">
                                        <div class="bd-highlight px-1">
                                            <img src="@/assets/icon/time.png" alt="">
                                        </div>
                                        <div class="bd-highlight px-1 pt-1">
                                             <div class="text-gray-3 fm-nunito fst-normal fw-600 fs-12 lh-12 mb-0">14:00 - 15:00</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bd-highlight d-flex flex-column w-100 py-2">
                        <div class="bd-highlight">
                            <p class="text-gray-1 fm-poppins fst-normal fw-600 fs-14 lh-21">Ringkasan Pemesanan</p>
                        </div>
                        <div class="bd-highlight">
                            <div class="grup-order">
                                <div class="bd-highlight d-flex flex-column">
                                    <p class="text-gray-1 fm-poppins fst-normal fw-600 fs-14 lh-21">Covid-19</p>
                                    <div class="bd-highligt d-flex align-items-center justify-content-between">
                                        <div class="bd-highlight">
                                            <p class="text-gray-3 fm-nunito fst-normal fw-400 fs-12 lh-14">SWAB PCR</p>
                                        </div>
                                         <div class="bd-highlight">
                                            <p class="text-gray-1 fm-nunito fst-normal fw-600 fs-12 lh-14">Rp. 200</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

.note {
    background: #ECFBFF;
    border-radius: 10px;
}

</style>

<script>
import SecondNavbar from '@/components/SecondNavbar.vue'

export default {
  components: {
    SecondNavbar
  }
}
</script>
